<!-- eslint-disable vue-i18n/no-raw-text -->
<template>
  <section class="add-car__section">
    <h4 class="add-car__section-heading">
      {{ $t('TECH_SECTION_TITLE') }}
    </h4>

    <div class="add-car__section-content">
      <div class="add-car__form-row">
        <ui-select
          fill="frame"
          :placeholder="$t('MANUFACTURER_PH')"
          :options="makerOptions"
          :value="form.maker"
          :is-error="Boolean(errors.maker)"
          @input="setFormField({ field: 'maker', value: $event })"
          class="add-car__form-field"
          has-filter
          filter-method="starts"
        >
          <span slot="error">
            {{ jsvLocalizeError(errors.maker) }}
          </span>
        </ui-select>

        <ui-select
          fill="frame"
          :placeholder="$t('MODEL_PH')"
          :options="modelOptions[form.maker]"
          :value="form.model"
          :is-error="Boolean(errors.model)"
          @input="setFormField({ field: 'model', value: $event })"
          class="add-car__form-field"
          has-filter
          filter-method="starts"
        >
          <span slot="error">
            {{ jsvLocalizeError(errors.model) }}
          </span>
        </ui-select>
      </div>
      <div class="add-car__form-row">
        <ui-select
          fill="frame"
          :placeholder="$t('FIRST_REGISTRATION_YEAR_PH')"
          :options="yearOptions"
          :value="form.firstRegistrationYear"
          :is-error="Boolean(errors.firstRegistrationYear)"
          @input="
            setFormField({
              field: 'firstRegistrationYear',
              value: $event
            })
          "
          class="add-car__form-field"
          has-filter
          filter-method="starts"
        >
          <span slot="error">
            {{ jsvLocalizeError(errors.firstRegistrationYear) }}
          </span>
        </ui-select>

        <ui-select
          fill="frame"
          class="add-car__form-field"
          :placeholder="$t('FUEL_TYPE_PH')"
          :options="fuelOptions"
          :value="form.fuelType"
          :is-error="Boolean(errors.fuelType)"
          @input="setFormField({ field: 'fuelType', value: $event })"
        >
          <span slot="error">
            {{ jsvLocalizeError(errors.fuelType) }}
          </span>
        </ui-select>
      </div>
      <div class="add-car__form-row">
        <ui-select
          fill="frame"
          :placeholder="$t('ENGINE_CAPACITY_PH')"
          :options="engineCapacityOptions"
          :value="form.engineCapacity"
          :is-error="Boolean(errors.engineCapacity)"
          @input="setFormField({ field: 'engineCapacity', value: $event })"
          class="add-car__form-field"
          has-filter
          filter-method="starts"
        >
          <span slot="error">
            {{ jsvLocalizeError(errors.engineCapacity) }}
          </span>
        </ui-select>

        <div class="add-car__form-field add-car__form-field_combo">
          <ui-text
            class="add-car__form-field"
            type="number"
            store-field="mileage"
            :placeholder="$t('MILEAGE_PH')"
            :min="0"
            :value="form.mileage"
            :is-error="Boolean(errors.mileage)"
            @input="setFormField({ field: 'mileage', value: $event })"
          >
            <span slot="error">
              {{ jsvLocalizeError(errors.mileage) }}
            </span>
          </ui-text>

          <ui-select
            fill="frame"
            class="add-car__form-field"
            :options="mileageUnitOptions"
            :value="form.mileageUnits"
            @input="setFormField({ field: 'mileageUnits', value: $event })"
          />
        </div>
      </div>
      <div class="add-car__form-row">
        <ui-select
          fill="frame"
          :placeholder="$t('WHEEL_PH')"
          :options="wheelOptions"
          :value="form.wheel"
          :is-error="Boolean(errors.wheel)"
          @input="setFormField({ field: 'wheel', value: $event })"
          class="add-car__form-field"
        >
          <span slot="error">
            {{ jsvLocalizeError(errors.wheel) }}
          </span>
        </ui-select>

        <ui-text
          :placeholder="$t('VIN_NUMBER_PH')"
          :value="form.vin"
          :is-error="Boolean(errors.vin)"
          @input="setFormField({ field: 'vin', value: $event })"
          class="add-car__form-field"
        >
          <span slot="error">
            {{ jsvLocalizeError(errors.vin) }}
          </span>
        </ui-text>
      </div>
      <div class="add-car__form-row">
        <ui-select
          fill="frame"
          :placeholder="$t('TRANSMISSION_PH')"
          :options="transmissionOptions"
          :value="form.transmission"
          :is-error="Boolean(errors.transmission)"
          @input="setFormField({ field: 'transmission', value: $event })"
          class="add-car__form-field"
        >
          <span slot="error">
            {{ jsvLocalizeError(errors.transmission) }}
          </span>
        </ui-select>

        <ui-select
          fill="frame"
          :placeholder="$t('COLOR_PH')"
          :options="colorOptions"
          :value="form.color"
          :is-error="Boolean(errors.color)"
          @input="setFormField({ field: 'color', value: $event })"
          class="
            add-car__form-field
            add-car__form-field_color
          "
        >
          <span slot="error">
            {{ jsvLocalizeError(errors.color) }}
          </span>
        </ui-select>
      </div>
      <div
        class="add-car__form-row"
        v-if="form.fuelType == 'ELECTRIC'"
      >
        <div class="input-box">
          <ui-text
            :placeholder="$t('Battery')"
            :value="form.battery"
            :is-error="Boolean(errors.battery)"
            @input="setFormField({ field: 'battery', value: $event })"
            class="add-car__form-field"
          />
          <span class="symbol">kWh</span>
        </div>
        <div class="input-box">
          <ui-text
            :placeholder="$t('Range')"
            :value="form.range"
            :is-error="Boolean(errors.range)"
            @input="setFormField({ field: 'range', value: $event })"
            class="add-car__form-field"
          />
          <span class="symbol">km</span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { UiText, UiSelect } from '@shelf.network/ui-kit'

import { mapGetters, mapMutations } from 'vuex'
import { getters, mutations } from '../store/types'
import { enumsGetters } from 'Store/entities/Enums/types'

import { jsvLocalizeError } from 'Utils/jsvLocalizeError'

export default {
  name: 'add-car-technical',
  components: {
    UiText,
    UiSelect
  },
  computed: {
    ...mapGetters('entities/enums', {
      modelOptions: enumsGetters.MODELS_AS_OPTIONS,
      yearOptions: enumsGetters.YEARS_AS_OPTIONS,
      fuelOptions: enumsGetters.FUELS_AS_OPTIONS,
      makerOptions: enumsGetters.MAKERS_AS_OPTIONS,
      wheelOptions: enumsGetters.WHEELS_AS_OPTIONS,
      colorOptions: enumsGetters.COLORS_AS_OPTIONS,
      mileageUnitOptions: enumsGetters.MILEAGE_UNITS_AS_OPTIONS,
      transmissionOptions: enumsGetters.TRANSMISSIONS_AS_OPTIONS,
      engineCapacityOptions: enumsGetters.ENGINE_VOLUMES_AS_OPTIONS
    }),
    ...mapGetters('ui/add-car', {
      form: getters.form,
      errors: getters.errors
    })
  },
  watch: {
    'form.maker'(oldV, newV) {
      if (oldV !== newV) {
        this.resetModel()
      }
    }
  },
  methods: {
    ...mapMutations('ui/add-car', {
      setFormField: mutations.SET_FORM_FIELD
    }),

    resetModel () {
      this.setFormField({ field: 'model', value: '' })
    },

    jsvLocalizeError
  }
}
</script>

<style lang="scss" scoped>
.input-box {
  position: relative;
  .symbol {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
  }
}
@import '../styles/add-car-form';
</style>

<i18n>
{
  "en": {
    "TECH_SECTION_TITLE": "Technical characteristics:",
    "MANUFACTURER_PH": "Manufacturer",
    "MODEL_PH": "Model",
    "FIRST_REGISTRATION_YEAR_PH": "First Registration Year",
    "FUEL_TYPE_PH": "Fuel Type",
    "ENGINE_CAPACITY_PH": "Engine Capacity",
    "MILEAGE_PH": "Mileage",
    "WHEEL_PH": "Wheel",
    "VIN_NUMBER_PH": "VIN Number",
    "Battery": "Battery",
    "Range": "Range",
    "TRANSMISSION_PH": "Transmission",
    "COLOR_PH": "Color"
  },
  "ka": {
    "TECH_SECTION_TITLE": "ᲢᲔᲥᲜᲘᲙᲣᲠᲘ ᲛᲐᲮᲐᲡᲘᲐᲗᲔᲑᲚᲔᲑᲘ:",
    "MANUFACTURER_PH": "მწარმოებელი",
    "MODEL_PH": "მოდელი",
    "FIRST_REGISTRATION_YEAR_PH": "რეგისტრაციის წელი",
    "FUEL_TYPE_PH": "საწვავის ტიპი",
    "ENGINE_CAPACITY_PH": "ძრავის მოცულობა",
    "MILEAGE_PH": "გარბენი",
    "WHEEL_PH": "საჭე",
    "VIN_NUMBER_PH": "ვინკოდი",
    "Battery": "ბატარეა",
    "Range": "დიაპაზონი",
    "TRANSMISSION_PH": "გადაცემათა კოლოფი",
    "COLOR_PH": "ფერი"
  },
  "ru": {
    "TECH_SECTION_TITLE": "Технические характеристики:",
    "MANUFACTURER_PH": "Производитель",
    "MODEL_PH": "Модель",
    "FIRST_REGISTRATION_YEAR_PH": "Год первой регистрации",
    "FUEL_TYPE_PH": "Тип топлива",
    "ENGINE_CAPACITY_PH": "Объем двигателя",
    "MILEAGE_PH": "Пробег",
    "WHEEL_PH": "Расположение руля",
    "VIN_NUMBER_PH": "VIN номер",
    "Battery": "Батарея",
    "Range": "Дальность",
    "TRANSMISSION_PH": "Коробка передач",
    "COLOR_PH": "Цвет"
  },
  "uk": {
    "TECH_SECTION_TITLE": "Технічні Характеристики:",
    "MANUFACTURER_PH": "Виробник",
    "MODEL_PH": "Модель",
    "FIRST_REGISTRATION_YEAR_PH": "Рік першої реєстрації",
    "FUEL_TYPE_PH": "Тип палива",
    "ENGINE_CAPACITY_PH": "Об'єм двигуна",
    "MILEAGE_PH": "Пробіг",
    "WHEEL_PH": "Розташування керма",
    "VIN_NUMBER_PH": "VIN номер",
    "Battery": "Батарея",
    "Range": "Дальність",
    "TRANSMISSION_PH": "Трансмісія",
    "COLOR_PH": "Колір"
  }
}
</i18n>
